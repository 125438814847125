/* You can add global styles to this file, and also import other style files */

:root {
  --app-fallback-color-primary: #444444;
  --app-fallback-color-primary-darken: darken(#444444, 20);
  --app-fallback-color-primary-lighten: lighten(#444444, 20);

  --app-fallback-color-secondary: inherit;
  --app-fallback-color-bg-primary: var(--app-fallback-color-primary);
  --app-fallback-color-bg-secondary: var(--app-fallback-color-secondary);
  --app-fallback-color-text-primary: rgba(0,0,0,.85);
 
  // --app-color-primary-langedrag: #9c3359;

  --app-color-primary     : var(--app-custom-color-primary,       var(--app-theme-color-primary,      --app-fallback-color-primary      ));
  --app-color-primary-darken     : var(--app-custom-color-primary-darken,       var(--app-theme-color-primary-darken,      --app-fallback-color-primary-darken      ));
  --app-color-primary-lighten     : var(--app-custom-color-primary-lighten,       var(--app-theme-color-primary-lighten,      --app-fallback-color-primary-lighten      ));
  
  --app-color-secondary   : var(--app-custom-color-secondary,     var(--app-theme-color-secondary,    --app-fallback-color-secondary    ));
  --app-color-bg-primary  : var(--app-custom-color-bg-primary,    var(--app-theme-color-bg-primary,   --app-fallback-color-bg-primary   ));
  --app-color-bg-secondary: var(--app-custom-color-bg-secondary,  var(--app-theme-color-bg-secondary, --app-fallback-color-bg-secondary ));
  --app-color-text-primary: var(--app-custom-color-text-primary,  var(--app-theme-color-text-primary, --app-fallback-color-text-primary ));   
   
  --lib-color-yellow:       rgb(255, 222, 23);
  --lib-color-yellow-orange:rgb(249, 166, 28);
  --lib-color-orange:       rgb(246, 129, 33);
  --lib-color-orange-red:   rgb(238, 62, 62);
  --lib-color-red:          rgb(237, 28, 36);
  --lib-color-red-purple:   rgb(163, 36, 143);
  --lib-color-purple:       rgb(92, 46, 145);
  --lib-color-purple-blue:  rgb(33, 64, 155);
  --lib-color-blue:         rgb(1, 102, 180);
  --lib-color-blue-green:   rgb(1, 170, 173);
  --lib-color-green:        rgb(0, 165, 96);
  --lib-color-green-yellow: rgb(112, 190, 68);

 // --lib-color-test: color.adjust($color-blue, $alpha: -0.4); 
}



@import '../../../../node_modules/quill/dist/quill.core.css';
@import '../../../../node_modules/quill/dist/quill.bubble.css';
@import '../../../../node_modules/quill/dist/quill.snow.css';


@import "../../../../node_modules/ng-zorro-antd/ng-zorro-antd.variable.min.css";


@import '../../../../node_modules/@mobiscroll/angular-ivy/dist/css/mobiscroll.scss';



@import "../../../btlib-core/src/styles/styles-tailwind-light.scss";

@import "../../../btlib-core/src/styles/styles-lib.scss";
@import '../../../btlib-core/src/styles/styles-widget.scss';

@import "../../../btlib-core/src/styles/styles-custom.scss";


quill-editor {
  width: 100%;
  .ql-editor {
    p {
      margin-bottom: 1em;
    }
  }
}




body{
  height: auto;
}

.container-1600 {
  // padding: 24px;
  max-width: 1600px;
  // margin: 0 auto;
}

html.cbapi-not-iframe .page-wrapper-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}


// @media (min-width: 1600px) {
//   html.cbapi-not-iframe .page-wrapper-container {
//     // padding: ;
//   }
// } 


@media (max-width: 575px) { // xs

} 
@media (min-width: 576px) { // sm

}
@media (min-width: 768px) { // md

}
@media (min-width: 992px) { // lg

}
@media (min-width: 1200px) { // xl

} 
@media (min-width: 1600px) { // xxl

} 



